import { Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom"

import { useEffect } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { AuthActionCreator } from "../actions/auth.action"
import { NotificationsActionsCreator } from "../actions/notifications.action"
import { DashboardHeader } from "../components/dashboard/dashboardHeader.component"
import AddTrackPackMain from "../components/dashboard/modals/addTrackPackModalSteps/addTrackPackMainModal"
import AdditionalTrackPackMain from "../components/dashboard/modals/additionalTrackPackModals/additionalTrackPackMain.component"
import ArchiveMainModal from "../components/dashboard/modals/archiveModals/archiveMainModal.component"
import PerformanceTrackerQuickTourModalComponent from "../components/dashboard/modals/quickTourModals/performanceTrackerQuickTour/index.component"
import TrackpackHubQuickTourModalComponent from "../components/dashboard/modals/quickTourModals/trackpackHubQuickTour/index.component"
import NotificationAlerComponent from "../components/dashboard/notfitication-alerts/index.component"
import { SideNavigation } from "../components/dashboard/sideNavigation.component"
import { ROUTES_CONFIG } from "../config/routes.config"
import { AccountStateSelector } from "../selectors/accountState.selector"

let apiCall: any = null
export const DashboardContainer = () => {
    let navigation = useNavigation()
    const navigate = useNavigate()
    const location = useLocation()
    const { userActionData } = useSelector(AccountStateSelector)

    const notifyError = (text: string) => toast.error(text)
    const dispatch = useDispatch()

    useEffect(() => {
        if (apiCall) clearTimeout(apiCall)
        apiCall = setTimeout(() => {
            dispatch(
                AuthActionCreator.getAccessLevel(
                    () => {},
                    (res: { message: string }) => {
                        notifyError(res.message)
                    }
                )
            )
        }, 2000)

        return () => {
            clearTimeout(apiCall)
        }
    }, [])

    useEffect(() => {
        if (userActionData !== undefined) {
            dispatch(
                NotificationsActionsCreator.getAllNotifications(
                    {},
                    { as_user: userActionData && userActionData?.id + "" }
                )
            )
        } else dispatch(NotificationsActionsCreator.getAllNotifications())
    }, [dispatch, userActionData])

    useEffect(() => {
        const { pathname, search } = location
        const params = new URLSearchParams(search)
        const asins = params.get("asins")
        const id = params.get("id")

        const updateTrackpackID = (trackpackID: string) => {
            localStorage.setItem("selectedTrackpackID", trackpackID)
            // @ts-ignore
            if (typeof window.updateSelectedTrackpackID === "function") {
                // @ts-ignore
                window.updateSelectedTrackpackID(trackpackID)
            }
        }

        if (id) {
            updateTrackpackID(id)

            if (asins) {
                dispatch(
                    NotificationsActionsCreator.notifiSelection({
                        notifiAsin: asins.split(","),
                        selectedTrackpackID: id,
                    })
                )
            }

            if (pathname) {
                navigate(ROUTES_CONFIG.dashboard)
            }
        }
    }, [location, dispatch, navigate])

    return (
        <div className="bg-userAccount-custom-gradient pt-[32px] pr-[28px] pb-[24px] pl-[24px] ">
            <DashboardHeader />
            <div className="flex flex-row items-start gap-[20px] w-full " style={{ height: "calc(100vh - 165px)" }}>
                <SideNavigation />
                {navigation.state !== "idle" ? <div className="is-loading ">Loading...</div> : null}

                {/* <div className="bg-white rounded-tl-2xl rounded-bl-2xl flex-1 h-full"> */}
                <div className="h-full" style={{ width: "calc(100% - 80px)" }}>
                    <Outlet />
                </div>
            </div>
            <AddTrackPackMain />
            <AdditionalTrackPackMain />
            <ArchiveMainModal />
            <PerformanceTrackerQuickTourModalComponent />
            <TrackpackHubQuickTourModalComponent />
            <NotificationAlerComponent />
        </div>
    )
}
