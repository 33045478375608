import dayjs from "dayjs"
import { Key, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { JSX } from "react/jsx-runtime"
import { NotificationsActionsCreator } from "../../actions/notifications.action"
import { ProductsActionCreator } from "../../actions/products.action"
import { ReactComponent as NoImage } from "../../assets/svgs/no-image.svg"
import { NotificationChevronDownIcon } from "../../assets/svgs/NotificationChevronDownIcon"
import { NotificationHalfRedIcon } from "../../assets/svgs/notificationHalfRedDot.svg"
import { NotificationHalfRedIconHover } from "../../assets/svgs/notificationHalfRedIconHover.svg"
import { NotificationRedIcon } from "../../assets/svgs/notificationRedDot.svg"
import { NotificationRedIconHover } from "../../assets/svgs/notificationRedIconHover.svg"
import { NotificationWhiteIcon } from "../../assets/svgs/notificationWhiteIcon.svg"
import { NotificationWhiteIconHover } from "../../assets/svgs/notificationWhiteIconHover.svg"
import { AsinIssuesList, img_url } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { renderNotificationIcon } from "../../helpers/util.helper"
import { AccountStateSelector } from "../../selectors/accountState.selector"
import { PrimaryText } from "../elements/primaryText.element"
import NotificationAsinCard from "./notificationAsinCard.component"

export const NotificationCard = (props: INotificationCard) => {
    const [menuIconClicked, setMenuIconClicked] = useState<number | null>(null)
    const [hoveredIcon, setHoveredIcon] = useState(false)
    const [selectedNotificationId, setSelectedNotificationId] = useState<number | null>(null)
    const { userActionData } = useSelector(AccountStateSelector)

    const notificationCardRefs = useRef<{ [key: number]: HTMLDivElement | null }>({})

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location

    const { setup_alerts_and_issues = [], listing_changes = [], newTp = [], asinAdded = [] } = props?.product || {}

    useEffect(() => {
        const storedNotificationId = sessionStorage.getItem("selectedNotificationId")
        if (storedNotificationId) {
            setSelectedNotificationId(Number(storedNotificationId))
        }

        const storedMenuState = sessionStorage.getItem("menuIconClicked")
        if (storedMenuState) {
            setMenuIconClicked(Number(storedMenuState))
        }
    }, [])

    const processNotifications = (notificationsArray = [], typesToFilter = []) => {
        if (notificationsArray.length > 0) {
            const allIssuesNull = notificationsArray.every((notification: { type: string }) =>
                // @ts-ignore
                typesToFilter.includes(notification?.type!)
            )
            const { length: notificationLength, [0]: firstNotification } = notificationsArray
            const { main_image_filename: mainImageFilename, created_at: notificationDate } = firstNotification || {}

            return { allIssuesNull, notificationLength, mainImageFilename, notificationDate }
        }
        return null
    }

    const notificationSetupAlerts = useMemo(
        () =>
            // @ts-ignore
            processNotifications(setup_alerts_and_issues, AsinIssuesList),
        [setup_alerts_and_issues]
    )

    const notificationListingChanges = useMemo(
        () =>
            // @ts-ignore
            processNotifications(listing_changes, ["LISTING_CHANGE"]),
        [listing_changes]
    )

    const notificationNewTp = useMemo(
        () =>
            // @ts-ignore
            processNotifications(newTp, ["NEW_TP"]),
        [newTp]
    )

    const notificationAsinAdded = useMemo(
        () =>
            // @ts-ignore
            processNotifications(asinAdded, ["ASIN_READY"]),
        [asinAdded]
    )

    const iconType = renderNotificationIcon(props?.product)

    const renderIcon = () => {
        switch (iconType) {
            case "red":
                return (
                    <div onMouseOver={() => setHoveredIcon(true)} onMouseLeave={() => setHoveredIcon(false)}>
                        {hoveredIcon ? <NotificationRedIconHover /> : <NotificationRedIcon />}
                        <div
                            style={{
                                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                            }}
                            className="opacity-0 min-w-[68px]  absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                        >
                            Mark as read
                        </div>
                    </div>
                )
            case "gray":
                return (
                    <div onMouseOver={() => setHoveredIcon(true)} onMouseLeave={() => setHoveredIcon(false)}>
                        {hoveredIcon ? <NotificationWhiteIconHover /> : <NotificationWhiteIcon />}
                        <div
                            style={{
                                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                            }}
                            className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 px-[6px] transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] rounded-[4px]"
                        >
                            Mark as unread
                        </div>
                    </div>
                )

            case "half":
                return (
                    <div onMouseOver={() => setHoveredIcon(true)} onMouseLeave={() => setHoveredIcon(false)}>
                        {hoveredIcon ? <NotificationHalfRedIconHover /> : <NotificationHalfRedIcon />}
                        <div
                            style={{
                                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                            }}
                            className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                        >
                            Mark as read
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    const handleTrackpackRedirect = (
        e: React.MouseEvent<HTMLDivElement>,
        id: number,
        asins: any[],
        notificationNumber: number
    ) => {
        e.stopPropagation()
        if (
            props?.product?.trackpack?.status === "ACTIVE" ||
            props?.product?.trackpack?.status === "AWAITING_INITIAL_DATA"
        ) {
            const asinsArray = asins.map((asin: any) => asin.requested_asin)
            dispatch(
                NotificationsActionsCreator.notifiSelection({
                    notifiAsin: asinsArray,
                    selectedTrackpackID: id?.toString(),
                })
            )
            localStorage.setItem("selectedTrackpackID", id.toString())
            // @ts-ignore
            if (typeof window.updateSelectedTrackpackID === "function") {
                // @ts-ignore
                window.updateSelectedTrackpackID(id.toString())
            }
            if (pathname !== ROUTES_CONFIG.dashboard) {
                navigate(ROUTES_CONFIG.dashboard)
            }
            dispatch(ProductsActionCreator.selectedOptions({ selectedOption: "Graph" }))

            if (!userActionData) {
                let payload = {
                    notification_ids: asins.flatMap((asin: any) =>
                        Array.isArray(asin.notification_id) ? asin.notification_id : [asin.notification_id]
                    ),
                }
                dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
            }
        }

        setSelectedNotificationId(notificationNumber)
        sessionStorage.setItem("selectedNotificationId", notificationNumber.toString())

        setMenuIconClicked(notificationNumber)
        sessionStorage.setItem("menuIconClicked", notificationNumber.toString())

        props.onClose!()
    }

    const handleMenuStateChange = (notificationNumber: number) => {
        const previousNotificationId = selectedNotificationId
        setSelectedNotificationId(notificationNumber)
        sessionStorage.setItem("selectedNotificationId", notificationNumber.toString())
        if (previousNotificationId !== null) {
            sessionStorage.removeItem(`menuIconClicked`)
            setMenuIconClicked(null)
        }
        setMenuIconClicked((prevState) => (!prevState ? notificationNumber : null))
        sessionStorage.setItem("menuIconClicked", notificationNumber.toString())
    }

    const handleChevronClick = (e: React.MouseEvent<HTMLDivElement>, notificationId: number) => {
        e.stopPropagation()
        if (menuIconClicked === notificationId) {
            setMenuIconClicked(null)
            sessionStorage.removeItem("menuIconClicked")
            sessionStorage.removeItem("selectedNotificationId")
        } else {
            setSelectedNotificationId(notificationId)
            sessionStorage.setItem("selectedNotificationId", notificationId.toString())
            setMenuIconClicked(notificationId)
            sessionStorage.setItem("menuIconClicked", notificationId.toString())
        }
    }

    const handleMarkAsRead = (e: React.MouseEvent<HTMLDivElement>, notifications: any[]) => {
        e.stopPropagation()
        if (!userActionData) {
            const isAllRead = notifications.every((notification) => notification.read_at !== null)

            let payload = {
                notification_ids: notifications.flatMap((notification: any) =>
                    Array.isArray(notification.notification_id)
                        ? notification.notification_id
                        : [notification.notification_id]
                ),
            }

            if (isAllRead) {
                dispatch(NotificationsActionsCreator.markNotificationAsUnRead({ ...payload, isUnread: true }))
            } else {
                dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
            }
        }
    }

    return (
        <>
            {setup_alerts_and_issues.length > 0 && (
                <div
                    className="relative flex gap-[12px] w-full cursor-pointer"
                    ref={(el) => (notificationCardRefs.current[props.product.trackpack?.notificationNumber] = el)}
                    onClick={(e) => handleMarkAsRead(e, [...setup_alerts_and_issues, ...listing_changes])}
                >
                    {notificationSetupAlerts?.mainImageFilename ? (
                        <img
                            src={`${img_url}${notificationSetupAlerts.mainImageFilename}`}
                            alt="Trackpack"
                            className="h-[44px] max-w-[44px] min-w-[44px] border border-gray-300 rounded-[6px] object-contain bg-white"
                        />
                    ) : (
                        <div className="h-[44px] min-w-[44px] border border-gray-300 rounded-[4px] flex items-center justify-center bg-gray-800">
                            <NoImage />
                        </div>
                    )}
                    <div className="w-full">
                        <div className="flex items-center justify-between mt-[2px] mb-[4px]">
                            <div className="flex items-center gap-[8px] ">
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-700">
                                    {notificationSetupAlerts?.notificationLength}{" "}
                                    {notificationSetupAlerts?.notificationLength &&
                                    notificationSetupAlerts?.notificationLength > 1
                                        ? "ASINs"
                                        : "ASIN"}{" "}
                                    {"error"}
                                </PrimaryText>
                                <PrimaryText
                                    weight="book"
                                    className="text-[#667085] text-[9px] leading-[10px] my-[4px]"
                                >
                                    {dayjs(notificationSetupAlerts?.notificationDate)
                                        .format("MMM-DD-YYYY")
                                        .toUpperCase()}
                                </PrimaryText>
                            </div>
                            <div className="mr-[10px] cursor-pointer relative group">{renderIcon()}</div>
                        </div>

                        <div className="flex items-start w-full justify-between">
                            <PrimaryText size="small" weight="light" className="text-gray-700 inline-block ">
                                <PrimaryText size="small" weight="light" className="text-gray-700 w-full ">
                                    Your Trackpack
                                    <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                        &nbsp;{props?.product?.trackpack?.name}&nbsp;
                                    </PrimaryText>
                                    has ASINs with errors&nbsp;&nbsp;
                                    <span
                                        className={`text-[#088AB2] font-medium text-sm inline-block ${
                                            props?.product?.trackpack?.status !== "ACTIVE"
                                                ? "cursor-not-allowed"
                                                : "cursor-pointer"
                                        } `}
                                        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                                            handleTrackpackRedirect(
                                                e,
                                                props?.product?.trackpack?.id!,
                                                [...setup_alerts_and_issues, ...listing_changes],
                                                props?.product?.trackpack?.notificationNumber!
                                            )
                                        }
                                    >
                                        Review now
                                    </span>
                                </PrimaryText>
                            </PrimaryText>

                            <div
                                className="w-[20px] h-[20px] cursor-pointer flex items-center mr-[10px]"
                                onClick={(e) => {
                                    handleChevronClick(e, props.product.trackpack.notificationNumber)
                                }}
                            >
                                <NotificationChevronDownIcon
                                    className={`cursor-pointer w-[20px] h-[20px] ${
                                        menuIconClicked === props.product.trackpack.notificationNumber
                                            ? "rotate-0"
                                            : "rotate-[-90deg]"
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!setup_alerts_and_issues.length && listing_changes.length > 0 && (
                <div
                    className="relative flex gap-[12px] w-full cursor-pointer"
                    ref={(el) => (notificationCardRefs.current[props.product.trackpack?.notificationNumber] = el)}
                    onClick={(e) => handleMarkAsRead(e, [...setup_alerts_and_issues, ...listing_changes])}
                >
                    {notificationListingChanges?.mainImageFilename ? (
                        <img
                            src={`${img_url}${notificationListingChanges.mainImageFilename}`}
                            alt="Trackpack"
                            className="h-[44px] max-w-[44px]  min-w-[44px] border border-gray-300 rounded-[6px] object-contain bg-white"
                        />
                    ) : (
                        <div className="h-[44px] min-w-[44px] border border-gray-300 rounded-[4px] flex items-center justify-center bg-gray-800">
                            <NoImage />
                        </div>
                    )}

                    <div className="w-full">
                        <div className="flex items-center justify-between mt-[2px] mb-[4px]">
                            <div className="flex items-center gap-x-[4px]">
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-700">
                                    Listing changes
                                </PrimaryText>
                                <PrimaryText weight="book" className="text-gray-500 text-[9px] leading-[10px] my-[px]">
                                    {dayjs(notificationListingChanges?.notificationDate)
                                        .format("MMM-DD-YYYY")
                                        .toUpperCase()}
                                </PrimaryText>
                            </div>

                            <div className="mr-[10px] cursor-pointer relative group">{renderIcon()}</div>
                        </div>
                        <div className="flex justify-between">
                            <PrimaryText size="small" weight="light" className="text-gray-700">
                                Your Trackpack&nbsp;
                                <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                    {props?.product?.trackpack?.name}
                                </PrimaryText>
                                &nbsp;has listing changes&nbsp;&nbsp;
                                <span
                                    className={`text-[#088AB2] font-medium text-sm inline-block ${
                                        props?.product?.trackpack?.status !== "ACTIVE"
                                            ? "cursor-not-allowed"
                                            : "cursor-pointer"
                                    } `}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                                        handleTrackpackRedirect(
                                            e,
                                            props?.product?.trackpack?.id!,
                                            [...setup_alerts_and_issues, ...listing_changes],
                                            props?.product?.trackpack?.notificationNumber!
                                        )
                                    }
                                >
                                    View now
                                </span>
                            </PrimaryText>

                            <div
                                className="w-[20px] h-[20px] cursor-pointer flex items-center mr-[10px]"
                                onClick={(e) => {
                                    handleChevronClick(e, props.product.trackpack.notificationNumber)
                                }}
                            >
                                <NotificationChevronDownIcon
                                    className={`cursor-pointer w-[20px] h-[20px] ${
                                        menuIconClicked === props?.product?.trackpack?.notificationNumber
                                            ? "rotate-0"
                                            : "rotate-[-90deg]"
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {newTp.length > 0 && (
                <div
                    className="relative flex gap-[12px] w-full cursor-pointer"
                    ref={(el) => (notificationCardRefs.current[props.product.trackpack?.notificationNumber] = el)}
                    onClick={(e) => handleMarkAsRead(e, newTp)}
                >
                    {notificationNewTp?.mainImageFilename ? (
                        <img
                            src={`${img_url}${notificationNewTp.mainImageFilename}`}
                            alt="Trackpack"
                            className="h-[44px] max-w-[44px] min-w-[44px] border border-gray-300 rounded-[6px] object-contain bg-white"
                        />
                    ) : (
                        <div className="h-[44px] min-w-[44px] border border-gray-300 rounded-[4px] flex items-center justify-center bg-gray-800">
                            <NoImage />
                        </div>
                    )}
                    <div className="w-full">
                        <div className="flex items-center justify-between mt-[2px] mb-[4px]">
                            <div className="flex items-center gap-x-[4px]">
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-700">
                                    Trackpack Ready
                                </PrimaryText>
                                <PrimaryText weight="book" className="text-gray-500 text-[9px] leading-[10px] my-[4px]">
                                    {dayjs(notificationNewTp?.notificationDate)
                                        .format("MMM-DD-YYYY")
                                        .toUpperCase()}
                                </PrimaryText>
                            </div>

                            <div className="mr-[10px] cursor-pointer relative group">{renderIcon()}</div>
                        </div>
                        <div className="flex justify-between">
                            <PrimaryText size="small" weight="light" className="text-gray-700">
                                Your Trackpack&nbsp;
                                <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                    {props?.product?.trackpack?.name}
                                </PrimaryText>
                                &nbsp;is ready&nbsp;&nbsp;
                                <span
                                    className={`text-[#088AB2] font-medium text-sm inline-block ${
                                        props?.product?.trackpack?.status !== "ACTIVE"
                                            ? "cursor-not-allowed"
                                            : "cursor-pointer"
                                    } `}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                                        handleTrackpackRedirect(
                                            e,
                                            props?.product?.trackpack?.id!,
                                            [...newTp],
                                            props?.product?.trackpack?.notificationNumber!
                                        )
                                    }
                                >
                                    View now
                                </span>
                            </PrimaryText>

                            <div
                                className="w-[20px] h-[20px] cursor-pointer flex items-center mr-[10px]"
                                onClick={(e) => {
                                    handleChevronClick(e, props.product.trackpack.notificationNumber)
                                }}
                            >
                                <NotificationChevronDownIcon
                                    className={`cursor-pointer w-[20px] h-[20px] ${
                                        menuIconClicked === props.product.trackpack.notificationNumber
                                            ? "rotate-0"
                                            : "rotate-[-90deg]"
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {asinAdded.length > 0 && (
                <div
                    className="relative flex gap-[12px] w-full cursor-pointer"
                    ref={(el) => (notificationCardRefs.current[props.product.trackpack?.notificationNumber] = el)}
                    onClick={(e) => handleMarkAsRead(e, asinAdded)}
                >
                    {notificationAsinAdded?.mainImageFilename ? (
                        <img
                            src={`${img_url}${notificationAsinAdded.mainImageFilename}`}
                            alt="Trackpack"
                            className="h-[44px] max-w-[44px] min-w-[44px] border border-gray-300 rounded-[6px] object-contain bg-white"
                        />
                    ) : (
                        <div className="h-[44px] min-w-[44px] border border-gray-300 rounded-[4px] flex items-center justify-center bg-gray-800">
                            <NoImage />
                        </div>
                    )}
                    <div className="w-full">
                        <div className="flex items-center justify-between mt-[2px] mb-[4px]">
                            <div className="flex items-center gap-x-[4px]">
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-700">
                                    ASINs ready
                                </PrimaryText>
                                <PrimaryText weight="book" className="text-gray-500 text-[9px] leading-[10px] my-[4px]">
                                    {dayjs(notificationAsinAdded?.notificationDate)
                                        .format("MMM-DD-YYYY")
                                        .toUpperCase()}
                                </PrimaryText>
                            </div>

                            <div className="mr-[10px] cursor-pointer relative group">{renderIcon()}</div>
                        </div>
                        <div className="flex justify-between">
                            <PrimaryText size="small" weight="light" className="text-gray-700">
                                New ASINs added to your Trackpack &nbsp;
                                <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                    {props?.product?.trackpack?.name}&nbsp;&nbsp;
                                </PrimaryText>
                                <span
                                    className={`text-[#088AB2] font-medium text-sm inline-block ${
                                        props?.product?.trackpack?.status !== "ACTIVE"
                                            ? "cursor-not-allowed"
                                            : "cursor-pointer"
                                    } `}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                                        handleTrackpackRedirect(
                                            e,
                                            props?.product?.trackpack?.id!,
                                            [...asinAdded],
                                            props?.product?.trackpack?.notificationNumber!
                                        )
                                    }
                                >
                                    View now
                                </span>
                            </PrimaryText>

                            <div
                                className="w-[20px] h-[20px] cursor-pointer flex items-center mr-[10px]"
                                onClick={(e) => {
                                    handleChevronClick(e, props.product.trackpack.notificationNumber)
                                }}
                            >
                                <NotificationChevronDownIcon
                                    className={`cursor-pointer w-[20px] h-[20px] ${
                                        menuIconClicked === props.product.trackpack.notificationNumber
                                            ? "rotate-0"
                                            : "rotate-[-90deg]"
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {menuIconClicked === props.product.trackpack.notificationNumber && setup_alerts_and_issues?.length > 0 && (
                <div className="mt-[16px]">
                    {setup_alerts_and_issues.map(
                        (item: JSX.IntrinsicAttributes & ProductNotification, index: Key | null | undefined) => (
                            <NotificationAsinCard
                                key={index}
                                notification_id={item.notification_id}
                                {...item}
                                trackpackId={props?.product?.trackpack?.id}
                                onClose={props.onClose}
                                amazon_tld={props?.product?.trackpack?.amazon_tld}
                                islistingChange={false}
                                notificationNumber={props?.product?.trackpack?.notificationNumber}
                                handleMenuStateChange={handleMenuStateChange}
                            />
                        )
                    )}
                </div>
            )}

            {menuIconClicked === props.product.trackpack.notificationNumber && listing_changes?.length > 0 && (
                <div className="mt-[16px]">
                    <NotificationAsinCard
                        trackpackId={props?.product?.trackpack?.id}
                        onClose={props.onClose}
                        islistingChange={true}
                        amazon_tld={props?.product?.trackpack?.amazon_tld}
                        listing_changes={listing_changes}
                        notificationNumber={props?.product?.trackpack?.notificationNumber}
                        handleMenuStateChange={handleMenuStateChange}
                    />
                </div>
            )}

            {menuIconClicked === props.product.trackpack.notificationNumber && newTp?.length > 0 && (
                <div className="mt-[16px]">
                    {newTp.map((item: JSX.IntrinsicAttributes & ProductNotification, index: Key | null | undefined) => (
                        <NotificationAsinCard
                            key={index}
                            notification_id={item.notification_id}
                            {...item}
                            trackpackId={props?.product?.trackpack?.id}
                            onClose={props.onClose}
                            amazon_tld={props?.product?.trackpack?.amazon_tld}
                            islistingChange={false}
                            notificationNumber={props?.product?.trackpack?.notificationNumber}
                            handleMenuStateChange={handleMenuStateChange}
                        />
                    ))}
                </div>
            )}

            {menuIconClicked === props.product.trackpack.notificationNumber && asinAdded?.length > 0 && (
                <div className="mt-[16px]">
                    {asinAdded.map(
                        (item: JSX.IntrinsicAttributes & ProductNotification, index: Key | null | undefined) => (
                            <NotificationAsinCard
                                key={index}
                                notification_id={item.notification_id}
                                {...item}
                                trackpackId={props?.product?.trackpack?.id}
                                onClose={props.onClose}
                                amazon_tld={props?.product?.trackpack?.amazon_tld}
                                islistingChange={false}
                                notificationNumber={props?.product?.trackpack?.notificationNumber}
                                handleMenuStateChange={handleMenuStateChange}
                            />
                        )
                    )}
                </div>
            )}
        </>
    )
}
