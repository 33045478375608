import { useEffect, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { NotificationsActionsCreator } from "../../actions/notifications.action"
import {
    clearDynamicMenuIconKeys,
    combineNotificationsTarckpack,
    transformNotificationsRecent,
} from "../../helpers/util.helper"
import { NotificationStateSelector } from "../../selectors/notificationStateSelector"
import { NotificationCard } from "../common/notificationCard.component"
import { NotificationCardByTrackpack } from "../common/notificationsByTrackpack.component"
import { PrimaryText } from "../elements/primaryText.element"

export const NotificationAlert = (props: INotificationCard) => {
    const initialShowOnlyUnread = sessionStorage.getItem("showOnlyUnread") === "true"
    const [showOnlyUnread, setShowOnlyUnread] = useState(initialShowOnlyUnread)

    const initialViewType = sessionStorage.getItem("viewType") || "recent"
    const [viewType, setViewType] = useState<"recent" | "trackpack">(initialViewType as "recent" | "trackpack")

    const [hasScroll, setHasScroll] = useState<boolean>(false)
    const [scrolled, setScrolled] = useState(false)
    const notificationRef = useRef<HTMLDivElement | null>(null)
    const dispatch = useDispatch()

    const { notificationsData } = useSelector(NotificationStateSelector)

    const toggleShowOnlyUnread = () => {
        setShowOnlyUnread((prev) => {
            const newValue = !prev
            sessionStorage.setItem("showOnlyUnread", newValue.toString())
            return newValue
        })
    }

    useEffect(() => {
        const checkForScroll = () => {
            if (notificationRef.current) {
                const hasVerticalScrollbar = notificationRef.current.scrollHeight > notificationRef.current.clientHeight
                setHasScroll(hasVerticalScrollbar)
            }
        }
        checkForScroll()
        window.addEventListener("resize", checkForScroll)
        const observer = new MutationObserver(() => {
            checkForScroll()
        })

        if (notificationRef.current) {
            observer.observe(notificationRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
                characterData: true,
            })
        }
        return () => {
            window.removeEventListener("resize", checkForScroll)
            observer.disconnect()
        }
    }, [])

    const saveScrollPosition = (): void => {
        if (notificationRef.current) {
            const scrollPosition = notificationRef.current.scrollTop
            sessionStorage.setItem("notificationScrollPosition", scrollPosition.toString())
        }
    }
    const restoreScrollPosition = (): void => {
        const savedPosition = sessionStorage.getItem("notificationScrollPosition")
        if (notificationRef.current && savedPosition) {
            notificationRef.current.style.scrollBehavior = "smooth"
            notificationRef.current.scrollTop = parseInt(savedPosition, 10)
        }
        setTimeout(() => {
            if (notificationRef.current) {
                notificationRef.current.style.scrollBehavior = "auto"
            }
        }, 300)
    }

    useEffect(() => {
        const refCurrent = notificationRef.current

        if (refCurrent) {
            refCurrent.addEventListener("scroll", saveScrollPosition)
        }

        restoreScrollPosition()

        return () => {
            if (refCurrent) {
                refCurrent.removeEventListener("scroll", saveScrollPosition)
            }
        }
    }, [])

    const forRecent = notificationsData?.notifications && JSON.parse(JSON.stringify(notificationsData?.notifications))
    const forTrackpack =
        notificationsData?.notifications && JSON.parse(JSON.stringify(notificationsData?.notifications))

    const trackPackNotfi = combineNotificationsTarckpack(forTrackpack as any[])
    const splitNotificationsRecent = transformNotificationsRecent(forRecent as any[])

    function filterNotifications(transformedNotifications: any[], showOnlyUnread: boolean) {
        return transformedNotifications
            .map((item) => {
                const filteredByDate = Object.entries(item)
                    .filter(([key, value]) => key !== "trackpackInfo")
                    .reduce(
                        (acc, [date, notifications]) => {
                            // @ts-ignore
                            const filteredNotifications = notifications.filter((notification: any) => {
                                if (showOnlyUnread) {
                                    return notification.read_at === null
                                }
                                return true
                            })

                            if (filteredNotifications.length > 0) {
                                acc[date] = filteredNotifications
                            }

                            return acc
                        },
                        {} as { [date: string]: any[] }
                    )

                if (Object.keys(filteredByDate).length > 0) {
                    return {
                        trackpackInfo: item.trackpackInfo,
                        ...filteredByDate,
                    }
                }

                return null
            })
            .filter(Boolean)
    }

    const filteredNotificationsByTrackpack = filterNotifications(trackPackNotfi, showOnlyUnread)

    const getNotificationIdsAndReadStatus = (data: any) => {
        const notificationIds: number[] = []
        let allNotificationsRead = true

        data?.forEach(
            (notification: { product_notifications: { listing_changes: any[]; setup_alerts_and_issues: any[] } }) => {
                notification.product_notifications.listing_changes?.forEach(
                    (change: { notification_id: number; read_at: string | null }) => {
                        notificationIds.push(change.notification_id)
                        if (change.read_at === null) {
                            allNotificationsRead = false
                        }
                    }
                )
                notification.product_notifications.setup_alerts_and_issues?.forEach(
                    (issue: { notification_id: number; read_at: string | null }) => {
                        notificationIds.push(issue.notification_id)
                        if (issue.read_at === null) {
                            allNotificationsRead = false
                        }
                    }
                )
            }
        )

        return {
            allNotificationIds: notificationIds,
            allNotificationsRead,
        }
    }

    const { allNotificationIds, allNotificationsRead } = getNotificationIdsAndReadStatus(
        notificationsData?.notifications
    )

    const markAllAsRead = () => {
        let payload = {
            notification_ids: allNotificationIds,
        }

        dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
    }

    const filteredRecentNotifications = splitNotificationsRecent.filter((notification: any) => {
        const { setup_alerts_and_issues, listing_changes, newTp, asinAdded } = notification || {}

        const hasUnreadNotifications = (notificationsArray: ProductNotification[]) =>
            notificationsArray?.some((notif) => notif?.read_at === null)

        if (showOnlyUnread) {
            return (
                hasUnreadNotifications(setup_alerts_and_issues) ||
                hasUnreadNotifications(listing_changes) ||
                hasUnreadNotifications(newTp) ||
                hasUnreadNotifications(asinAdded)
            )
        }

        return (
            newTp?.length > 0 ||
            asinAdded?.length > 0 ||
            setup_alerts_and_issues?.length > 0 ||
            listing_changes?.length > 0
        )
    })

    const handleViewTypeChange = (type: "recent" | "trackpack") => {
        setViewType(type)
        sessionStorage.setItem("viewType", type)
        sessionStorage.removeItem("selectedNotificationId")
        sessionStorage.removeItem("menuIconClicked")
        clearDynamicMenuIconKeys()
        if (notificationRef.current) {
            notificationRef.current.scrollTop = 0
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (notificationRef.current && notificationRef.current.scrollTop > 0) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }

        if (notificationRef.current) {
            notificationRef.current.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (notificationRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                notificationRef.current.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    return (
        <div
            className={
                "w-[386px] bg-white right-0 absolute p-[12px] rounded-[16px] pb-0 border-l-gray-200 top-[43px] z-50"
            }
            style={{
                zIndex: "1000",
                // height:'calc(100vh - 108px)',
                border: "1px solid",
                borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
            }}
        >
            <div className={"flex justify-between pb-[4px] pt-[8px] pl-[6px] pr-[0px]"}>
                <PrimaryText size={"xl"} weight={"medium"} className="text-gray-700">
                    Alerts
                </PrimaryText>
                <div className="flex items-center gap-[4px]">
                    <PrimaryText size="xs" weight="medium" className="text-[#088AB2]">
                        Show only unread
                    </PrimaryText>
                    <label className="switch">
                        <input
                            id="toggle-input"
                            type="checkbox"
                            checked={showOnlyUnread}
                            onChange={toggleShowOnlyUnread}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
            <div className="flex items-center justify-between mt-[10px] pb-[12px] ">
                <div className="flex items-center gap-[4px]">
                    <button
                        className={`flex items-center justify-center px-[12px] py-[4px] ${
                            viewType === "recent" ? "bg-[#A5F0FC] text-[#0E7090]" : "bg-gray-200 text-gray-500"
                        } rounded-[16px] text-[12px] leading-[14px] font-[300]`}
                        onClick={() => handleViewTypeChange("recent")}
                    >
                        Recent
                    </button>
                    <button
                        className={`flex items-center justify-center px-[12px] py-[4px] ${
                            viewType === "trackpack" ? "bg-[#A5F0FC] text-[#0E7090]" : "bg-gray-200 text-gray-500"
                        } rounded-[16px] text-[12px] font-[300] leading-[14px]`}
                        onClick={() => handleViewTypeChange("trackpack")}
                    >
                        Trackpack
                    </button>
                </div>
                <PrimaryText
                    weight="medium"
                    size="xs"
                    className={`cursor-pointer ${
                        allNotificationsRead ? "cursor-not-allowed text-gray-300" : "text-gray-500"
                    }`}
                    onClick={!allNotificationsRead ? markAllAsRead : undefined}
                >
                    Mark all as read
                </PrimaryText>
            </div>
            <div className="border-b border-gray-200" />
            <div
                ref={notificationRef}
                className={` overflow-auto ${hasScroll ? "pr-[8px]" : ""} notification-scroll`}
                style={{
                    maxHeight: "calc(100vh - 200px)",
                    paddingTop: scrolled ? "0px" : "10px",
                }}
            >
                {viewType === "recent" ? (
                    filteredRecentNotifications?.length > 0 ? (
                        filteredRecentNotifications?.map((product: any, id: number) => (
                            <div key={id} className={"bg-[#EAECF0] rounded-[8px] mb-[12px] p-[12px] last:mb-[12px]"}>
                                <NotificationCard product={product} onClose={props.onClose} />
                            </div>
                        ))
                    ) : (
                        <PrimaryText
                            size="small"
                            weight="book"
                            className="text-gray-600 italic text-center  mb-[24px] mt-[16px]"
                        >
                            No unread messages
                        </PrimaryText>
                    )
                ) : filteredNotificationsByTrackpack?.length > 0 ? (
                    filteredNotificationsByTrackpack?.map((product, id) => (
                        <div key={id} className={"bg-[#EAECF0] rounded-[8px] mb-[12px] p-[12px] last:mb-[12px]"}>
                            <NotificationCardByTrackpack product={product} onClose={props.onClose} isByTrackpack />
                        </div>
                    ))
                ) : (
                    <PrimaryText
                        size="small"
                        weight="book"
                        className="text-gray-600 italic text-center  mb-[24px] mt-[16px]"
                    >
                        No unread messages
                    </PrimaryText>
                )}
            </div>
        </div>
    )
}
