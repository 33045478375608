import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import GraphSkeletonImage from "../../../assets/images/graphSkelton.png"
import { ReactComponent as Logo } from "../../../assets/svgs/dashboard-logo.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { PrimaryText } from "../../elements/primaryText.element"

const GraphSkeletonWithTrackpacks = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const handleTrackpackOpen = () => {
        dispatch(
            RuntimeActionCreators.openNewTrackPackAsinModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleManageTrackpacks = () => {
        navigate(ROUTES_CONFIG.trackpackCentral)
    }

    const isSingleTrackpack = selectedDropdownList?.trackpacks?.length === 1

    return (
        <div className="w-full relative" style={{ height: "calc(100vh - 243px)" }}>
            <img src={GraphSkeletonImage} alt="" className="h-full w-full" />
            <div className="z-10 max-w-[338px] flex flex-col items-center w-full p-[24px] rounded-[12px] absolute left-[50%] transform translate-x-[-50%] top-[92px] border-[2px]  border-[#088AB2]">
                <Logo />
                <PrimaryText weight="medium" size="small" className="text-gray-600 text-center mt-[12px]">
                    I’m fetching data for your new Trackpack.
                    <br />
                    This’ll just take me a few minutes.
                    <br /> I’ll alert you once it’s ready.
                </PrimaryText>
                {isSingleTrackpack ? (
                    <PrimaryText weight="light" size="xs" className="mt-[4px] text-gray-600">
                        <PrimaryText
                            weight="medium"
                            size="xs"
                            className="text-[#088AB2] inline cursor-pointer"
                            onClick={() => handleTrackpackOpen()}
                        >
                            Add more
                        </PrimaryText>
                        &nbsp;Trackpacks now.
                    </PrimaryText>
                ) : (
                    <PrimaryText weight="light" size="xs" className="mt-[4px] text-gray-600">
                        <PrimaryText
                            weight="medium"
                            size="xs"
                            className="text-[#088AB2] inline cursor-pointer"
                            onClick={() => handleTrackpackOpen()}
                        >
                            Add New
                        </PrimaryText>
                        &nbsp;or&nbsp;
                        <PrimaryText
                            weight="medium"
                            size="xs"
                            className="text-[#088AB2] inline cursor-pointer"
                            onClick={() => handleManageTrackpacks()}
                        >
                            Manage
                        </PrimaryText>
                        &nbsp;Trackpacks now?
                    </PrimaryText>
                )}
            </div>
        </div>
    )
}

export default GraphSkeletonWithTrackpacks
