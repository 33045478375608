import { FocusEvent, useState } from "react"
import { Controller } from "react-hook-form"
// @ts-ignore
import CreatableSelect from "react-select/creatable"
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"

const createOption = (label: string) => ({
    label,
    value: label,
})

const createOptionsFromString = (value: string) => {
    return value
        ? value?.split(",").map((value: string) => {
              return createOption(value)
          })
        : []
}

const TagInputElement = (props: ITagInputElementProps) => {
    const [inputValue, setInputValue] = useState("")

    const extractASIN = (input: string) => {
        const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})/
        const match = input.match(asinRegex)
        return match ? match[1] : input
    }

    const isValidASINOrURL = (input: string) => {
        const asinRegex = /^[A-Z0-9]{10}$/
        const amazonAsinRegex =
            /^(?:https?:\/\/)?(?:www\.)?amazon\.[a-z.]+\/(?:dp|gp\/product|ASIN)\/([A-Z0-9]{10})(?:\/)?$/

        if (asinRegex.test(input)) {
            return true
        }
        if (amazonAsinRegex.test(input)) {
            return true
        }
        return false
    }

    const validateTags = (value: string) => {
        if (!value || value.trim() === "") {
            return "Please enter at least one ASIN number to continue"
        }

        const tags = value.split(",").map((tag) => tag.trim())
        const uniqueTags = new Set()
        const duplicates = []

        for (let tag of tags) {
            const extractedASIN = extractASIN(tag)

            if (uniqueTags.has(extractedASIN)) {
                duplicates.push(extractedASIN) // Track duplicates
            } else {
                uniqueTags.add(extractedASIN)
            }

            if (extractedASIN.length !== 10) {
                return "ASIN numbers must contain 10 characters"
            }

            if (!isValidASINOrURL(tag)) {
                return "Please enter a valid ASIN or URL to continue"
            }
        }

        if (duplicates.length > 0) {
            return "ASIN already entered"
        }

        return true
    }

    function findInvalidIndexes(options: { label: string; value: string }[]) {
        const invalidIndexes = []
        const uniqueTags = new Set()

        for (let i = 0; i < options.length; i++) {
            const extractedASIN = extractASIN(options[i].label)

            if (extractedASIN.length !== 10) {
                invalidIndexes.push(i)
            }

            if (uniqueTags.has(extractedASIN)) {
                invalidIndexes.push(i)
            } else {
                uniqueTags.add(extractedASIN)
            }
        }
        return invalidIndexes
    }

    return (
        <Controller
            rules={{ validate: validateTags }}
            control={props.reactHookControl}
            name={props.name}
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                const options = createOptionsFromString(value)
                const isSelectedError = error && findInvalidIndexes(options)

                const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
                    event.preventDefault()
                    if (inputValue.trim()) {
                        const extractedASIN = extractASIN(inputValue.trim())
                        if (value) {
                            onChange(value + "," + extractedASIN)
                        } else {
                            onChange(extractedASIN)
                        }
                        setInputValue("")
                    }
                }

                return (
                    <>
                        <CreatableSelect
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: error?.message ? "#FECDCA" : "#D0D5DD",
                                    boxShadow: value && "0px 1px 2px 0px #1018280D",
                                    borderRadius: "12px",
                                    padding: "9px 14px 9px 11px",
                                    "&:hover": {
                                        borderColor: "#67E8F9",
                                    },
                                }),
                                input: (baseStyles) => ({
                                    ...baseStyles,
                                    height: 37,
                                    minHeight: 37,
                                    position: "relative",
                                    top: 0,
                                }),
                                multiValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    backgroundColor: isSelectedError?.includes(state.index)
                                        ? "#FEE4E2"
                                        : baseStyles.backgroundColor,
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                }),
                                multiValueLabel: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                }),
                                multiValueRemove: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                    "&:hover": {
                                        backgroundColor: isSelectedError?.includes(state.index)
                                            ? "#FEE4E2"
                                            : baseStyles.backgroundColor,
                                        color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                    },
                                }),
                            }}
                            ref={ref}
                            name={name}
                            value={options}
                            components={{
                                DropdownIndicator: null,
                                ClearIndicator: () => {
                                    return (
                                        <>
                                            {error?.message ? <AlertIcon /> : ""}
                                            <div className="pr-2">
                                                {props.postIcon && !error?.message && props.postIcon}
                                            </div>
                                        </>
                                    )
                                },
                            }}
                            inputValue={inputValue}
                            onBlur={handleBlur}
                            isClearable
                            menuIsOpen={false}
                            onInputChange={(newValue: string) => setInputValue(newValue)}
                            onChange={(newValue) => {
                                let value = newValue?.map((option) => extractASIN(option.label)).join(",")
                                onChange(value)
                            }}
                            onKeyDown={(event) => {
                                if (!inputValue) return
                                switch (event.key) {
                                    case "Enter":
                                    case ",":
                                    case "Tab":
                                        const extractedASIN = extractASIN(inputValue.trim())
                                        if (value) {
                                            onChange(value + "," + extractedASIN)
                                        } else {
                                            onChange(extractedASIN)
                                        }
                                        setInputValue("")
                                        event.preventDefault()
                                }
                            }}
                            placeholder={props.placeholder}
                            className={props.className}
                            isMulti={true}
                            isDisabled={props.disabled}
                        />

                        {error && (
                            <span
                                className="text-[#F04438] pl-[14px] pt-2 text-[12px] font-[300]"
                                style={{ display: "block" }}
                            >
                                {error.message}
                            </span>
                        )}
                    </>
                )
            }}
        />
    )
}

export default TagInputElement
