import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import GraphSkeletonImage from "../../../assets/images/graphSkelton.png"
import { NewLogoIcon } from "../../../assets/svgs/LogoMark.svg"
import { PrimaryText } from "../../elements/primaryText.element"

const GraphSkeletonWithoutTrackpacks = () => {
    const dispatch = useDispatch()
    const handleTrackpackOpen = () => {
        dispatch(
            RuntimeActionCreators.openNewTrackPackAsinModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <div className="w-full relative" style={{ height: "calc(100vh - 243px)" }}>
            <img src={GraphSkeletonImage} alt="" className="h-full w-full" />
            <div className="z-10 max-w-[338px] min-h-[170px] max-h-[170px] flex flex-col items-center w-full p-[24px] rounded-[12px] absolute left-[50%] transform translate-x-[-50%] top-[92px] border-[2px] border-dashed border-[#D92D20]">
                <NewLogoIcon />
                <PrimaryText weight="medium" size="small" className="text-gray-600 text-center mt-[12px]">
                    Heads up! You need to create a Trackpack before you do anything else.
                </PrimaryText>
                <PrimaryText weight="light" size="xs" className="mt-[4px] text-gray-600">
                    <PrimaryText
                        weight="medium"
                        size="xs"
                        className="text-[#D92D20] inline cursor-pointer"
                        onClick={() => handleTrackpackOpen()}
                    >
                        Click here
                    </PrimaryText>
                    &nbsp;to get started.
                </PrimaryText>
            </div>
        </div>
    )
}

export default GraphSkeletonWithoutTrackpacks
