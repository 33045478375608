import AustraliaImg from "../assets/images/aus.jpg"
import BelgiumImg from "../assets/images/belgium.png"
import BrazilImg from "../assets/images/brazil.jpg"
import CanadaImg from "../assets/images/canada.jpg"
import ChineImg from "../assets/images/china.jpg"
import EgyptImg from "../assets/images/egypt.jpg"
import FranceImg from "../assets/images/france.jpg"
import GermanyImg from "../assets/images/germany.png"
import trackpackImg from "../assets/images/image11.png"
import IndiaImg from "../assets/images/india.png"
import ItalyImg from "../assets/images/italy.png"
import JapanImg from "../assets/images/japan.png"
import MexicoImg from "../assets/images/maxico.jpg"
import NetherlandImg from "../assets/images/netherland.jpg"
import PolandImg from "../assets/images/poleand.png"
import SaudiaImg from "../assets/images/saudia.png"
import SpainImg from "../assets/images/spanin.jpg"
import SwedenImg from "../assets/images/sweden.jpg"
import TurkeyImg from "../assets/images/turkey.jpg"
import UaeImg from "../assets/images/uae.jpg"
import UkImg from "../assets/images/uk.png"

import SindaporeImg from "../assets/images/singapore.jpg"
import AsinChangeIcon from "../assets/svgs/filters/AsinChangeFilterIcon.svg"
import BulletIcon from "../assets/svgs/filters/BulletFilterIcon.svg"
import CarouselIcon from "../assets/svgs/filters/CarouselFilterIcon.svg"
import DescriptionIcon from "../assets/svgs/filters/DescriptionFilterIcon.svg"
import MainImageIcon from "../assets/svgs/filters/MainImageFilterIcon.svg"
import PriceIcon from "../assets/svgs/filters/PriceFilterIcon.svg"
import StockIcon from "../assets/svgs/filters/StockFilterIcon.svg"
import TitleIcon from "../assets/svgs/filters/TitleFilterIcon.svg"
import SellerIcon from "../assets/svgs/filters/TopSellerFilterIcon.svg"
import VideoIcon from "../assets/svgs/filters/VideoFilterIcon.svg"
import UsaIcon from "../assets/svgs/usaIcon.png"

export const DashboardBoardConfig = {
    graphConfig: {
        productDifferentiateItems: [
            { value: "actual_asin", label: "ASIN", component: AsinChangeIcon, width: "32px" },
            { value: "seller_info", label: "Seller Info", component: SellerIcon, width: "54px" },
            { value: "stock", label: "Stock", component: StockIcon, width: "35px" },
            { value: "price", label: "Price", component: PriceIcon, width: "32px" },
            { value: "title", label: "Title", component: TitleIcon, width: "30px" },
            { value: "bullets", label: "Bullets", component: BulletIcon, width: "40px" },
            { value: "description", label: "Description", component: DescriptionIcon, width: "58px" },
            { value: "main_image", label: "Main Image", component: MainImageIcon, width: "59px" },
            { value: "carousel_images", label: "Carousel", component: CarouselIcon, width: "48px" },
            { value: "videos", label: "Video", component: VideoIcon, width: "34px" },
        ],
        productResults: [
            { value: "bsr_large", label: "BSR L Cat", LogViewLabel: "BSR Large Category" },
            { value: "bsr_small", label: "BSR S Cat", LogViewLabel: "BSR Small Category" },
            { value: "ratings_count", label: "Ratings Count", LogViewLabel: "Ratings Count" },
            { value: "rating", label: "Review Score", LogViewLabel: "Review Score" },
        ],
        productRange: [
            { value: "daily", label: "Daily" },
            { value: "weekly", label: "Weekly" },
            { value: "monthly", label: "Monthly" },
        ],
        productRangeUpdated: [
            { value: 7, label: "1<span className='text-[9px]'>W</span>", labelName: "1 Week", width: "42px" },
            { value: 14, label: "2<span className='text-[9px]'>W</span>", labelName: "2 Week", width: "46px" },
            { value: 30, label: "1<span className='text-[9px]'>M</span>", labelName: "1 Month", width: "48px" },
            { value: 180, label: "6<span className='text-[9px]'>M</span>", labelName: "6 Months", width: "50px" },
            { value: 0, label: "A<span className='text-[9px]'>LL</span>", labelName: "All", width: "28px" },
        ],
    },
}

export const img_url = "https://amaizing.sfo3.digitaloceanspaces.com/public/images/"

export const TrackPacks = [
    {
        title: "Men’s Suit Jackets",
        userASINs: 1,
        competitorASINs: 7,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5RVASV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5CRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "60 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
    {
        title: "Men’s T-Shirts",
        userASINs: 1,
        competitorASINs: 4,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 5,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },

            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],

        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5CRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "60 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
]

export const rowData = [
    {
        ASINs: {
            asinNo: "B08N5WRWNV",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "Ah",
        "Date Added": "2023-06-01",
        time_deletion: "120 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWNZ",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JH",
        "Date Added": "2023-06-02",
        time_deletion: "90 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWNY",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "AL",
        "Date Added": "2023-06-03",
        time_deletion: "80 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WDWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "BO",
        "Date Added": "2023-06-04",
        time_deletion: "110 day",
    },
    {
        ASINs: {
            asinNo: "B08N5XRWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JD",
        "Date Added": "2023-06-01",
        time_deletion: "40 day",
    },
    {
        ASINs: {
            asinNo: "B08N5CRWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "60 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WFWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "190 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWxx",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "20 day",
    },
]

export const countriesList = [
    {
        value: "USA",
        label: "USA",
        flagUrl: UsaIcon,
    },
    {
        value: "australia",
        label: "Australia",
        flagUrl: AustraliaImg,
    },
    {
        value: "belgium",
        label: "Belgium",
        flagUrl: BelgiumImg,
    },
    {
        value: "brazil",
        label: "Brazil",
        flagUrl: BrazilImg,
    },
    {
        value: "canada",
        label: "Canada",
        flagUrl: CanadaImg,
    },

    {
        value: "china",
        label: "China",
        flagUrl: ChineImg,
    },
    {
        value: "egypt",
        label: "Egypt",
        flagUrl: EgyptImg,
    },
    {
        value: "france",
        label: "France",
        flagUrl: FranceImg,
    },
    {
        value: "germany",
        label: "Germany",
        flagUrl: GermanyImg,
    },
    {
        value: "india",
        label: "India",
        flagUrl: IndiaImg,
    },
    {
        value: "italy",
        label: "Italy",
        flagUrl: ItalyImg,
    },

    {
        value: "japan",
        label: "Japan",
        flagUrl: JapanImg,
    },
    {
        value: "mexico",
        label: "Mexico",
        flagUrl: MexicoImg,
    },
    {
        value: "netherland",
        label: "Netherland",
        flagUrl: NetherlandImg,
    },
    {
        value: "poland",
        label: "Poland",
        flagUrl: PolandImg,
    },
    {
        value: "saudia_arabia",
        label: "Saudia Arabia",
        flagUrl: SaudiaImg,
    },
    {
        value: "singapore",
        label: "Singapore",
        flagUrl: SindaporeImg,
    },
    {
        value: "spain",
        label: "Spain",
        flagUrl: SpainImg,
    },
    {
        value: "sweden",
        label: "Sweden",
        flagUrl: SwedenImg,
    },
    {
        value: "turkey",
        label: "Turkey",
        flagUrl: TurkeyImg,
    },
    {
        value: "uae",
        label: "UAE",
        flagUrl: UaeImg,
    },
    {
        value: "united_kindom",
        label: "United Kindom",
        flagUrl: UkImg,
    },
]

export const AsinIssuesList = [
    "DIFFERENT_ASIN_RETURNED",
    "CHILD_ASIN_RETURNED",
    "DATA_UNAVAILABLE",
    "DIFFERENT_LARGE_CATEGORY",
]

export const notificationsData = {
    notifications: [
        {
            trackpack: {
                id: 228,
                user: 93,
                name: "25 PC Test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Tools & Home Improvement",
                created_at: "2024-09-25T09:27:56.155Z",
                last_updated_at: "2024-09-27T15:42:09.807Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5458,
                        read_at: null,
                        created_at: "2024-10-08T12:03:42.286Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "bullets_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-10-08T12:01:01.740Z",
                    },
                    {
                        notification_id: 5027,
                        read_at: null,
                        created_at: "2024-10-05T00:02:54.193Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-05T00:01:01.399Z",
                    },
                    {
                        notification_id: 5597,
                        read_at: null,
                        created_at: "2024-10-09T00:03:50.234Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:53.708Z",
                    },
                    {
                        notification_id: 4743,
                        read_at: "2024-10-02T13:06:42.348Z",
                        created_at: "2024-10-02T00:03:45.090Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-02T00:01:37.076Z",
                    },
                    {
                        notification_id: 4767,
                        read_at: "2024-10-02T13:08:46.212Z",
                        created_at: "2024-10-02T12:05:28.436Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-02T12:00:50.939Z",
                    },
                    {
                        notification_id: 4860,
                        read_at: "2024-10-04T14:24:59.442Z",
                        created_at: "2024-10-03T12:05:34.188Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-03T12:01:10.181Z",
                    },
                    {
                        notification_id: 5087,
                        read_at: null,
                        created_at: "2024-10-05T12:07:39.517Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-05T12:02:36.755Z",
                    },
                    {
                        notification_id: 5307,
                        read_at: null,
                        created_at: "2024-10-07T12:03:40.905Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T12:01:39.263Z",
                    },
                    {
                        notification_id: 4121,
                        read_at: null,
                        created_at: "2024-09-26T12:02:38.704Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T12:00:59.342Z",
                    },
                    {
                        notification_id: 4208,
                        read_at: null,
                        created_at: "2024-09-27T00:02:48.554Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T00:01:06.072Z",
                    },
                    {
                        notification_id: 4232,
                        read_at: null,
                        created_at: "2024-09-27T12:06:39.107Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T12:01:20.349Z",
                    },
                    {
                        notification_id: 4579,
                        read_at: "2024-10-02T14:27:19.816Z",
                        created_at: "2024-09-30T12:02:46.698Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "videos"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-30T12:01:04.698Z",
                    },
                    {
                        notification_id: 5572,
                        read_at: null,
                        created_at: "2024-10-09T00:03:47.793Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:50.015Z",
                    },
                    {
                        notification_id: 4422,
                        read_at: null,
                        created_at: "2024-09-29T12:05:20.682Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-29T12:03:16.042Z",
                    },
                    {
                        notification_id: 5660,
                        read_at: null,
                        created_at: "2024-10-09T12:04:38.671Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T12:00:59.398Z",
                    },
                    {
                        notification_id: 4919,
                        read_at: "2024-10-04T14:24:54.701Z",
                        created_at: "2024-10-04T00:03:47.550Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-04T00:01:14.807Z",
                    },
                    {
                        notification_id: 4726,
                        read_at: "2024-10-02T11:41:51.581Z",
                        created_at: "2024-10-02T00:03:42.803Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [],
                        total_changes: 0,
                        pd_timestamp: "2024-10-02T00:01:07.373Z",
                    },
                    {
                        notification_id: 4850,
                        read_at: "2024-10-04T14:24:59.442Z",
                        created_at: "2024-10-03T12:05:32.490Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-03T12:01:05.619Z",
                    },
                    {
                        notification_id: 4104,
                        read_at: null,
                        created_at: "2024-09-26T12:02:36.220Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T12:00:52.306Z",
                    },
                    {
                        notification_id: 4205,
                        read_at: null,
                        created_at: "2024-09-27T00:02:48.373Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T00:01:05.369Z",
                    },
                    {
                        notification_id: 4221,
                        read_at: null,
                        created_at: "2024-09-27T12:06:37.373Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T12:00:44.975Z",
                    },
                    {
                        notification_id: 4349,
                        read_at: null,
                        created_at: "2024-09-28T12:02:34.577Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-28T12:00:47.860Z",
                    },
                    {
                        notification_id: 4042,
                        read_at: null,
                        created_at: "2024-09-26T00:02:28.566Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T00:00:55.258Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5561,
                        read_at: null,
                        created_at: "2024-10-09T00:03:46.330Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:47.153Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 194,
                user: 93,
                name: "Test Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-11T08:25:40.537Z",
                last_updated_at: "2024-09-27T15:42:09.638Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5563,
                        read_at: null,
                        created_at: "2024-10-09T00:03:46.334Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:47.153Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 179,
                user: 93,
                name: "Hamza test 2",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-04T11:52:29.714Z",
                last_updated_at: "2024-09-27T15:42:09.496Z",
                next_tracking_order: 1,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5564,
                        read_at: null,
                        created_at: "2024-10-09T00:03:46.337Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:47.153Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5242,
                        read_at: null,
                        created_at: "2024-10-07T00:06:22.106Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T00:01:04.949Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 194,
                user: 93,
                name: "Test Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-11T08:25:40.537Z",
                last_updated_at: "2024-09-27T15:42:09.638Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5244,
                        read_at: null,
                        created_at: "2024-10-07T00:06:22.109Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T00:01:04.949Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 179,
                user: 93,
                name: "Hamza test 2",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-04T11:52:29.714Z",
                last_updated_at: "2024-09-27T15:42:09.496Z",
                next_tracking_order: 1,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5245,
                        read_at: null,
                        created_at: "2024-10-07T00:06:22.111Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T00:01:04.949Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4466,
                        read_at: "2024-10-01T16:30:17.896Z",
                        created_at: "2024-09-30T00:02:36.809Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:43.032Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 179,
                user: 93,
                name: "Hamza test 2",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-04T11:52:29.714Z",
                last_updated_at: "2024-09-27T15:42:09.496Z",
                next_tracking_order: 1,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4469,
                        read_at: null,
                        created_at: "2024-09-30T00:02:36.814Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:43.032Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 194,
                user: 93,
                name: "Test Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-11T08:25:40.537Z",
                last_updated_at: "2024-09-27T15:42:09.638Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4468,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-30T00:02:36.812Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:43.032Z",
                    },
                    {
                        notification_id: 4884,
                        read_at: null,
                        created_at: "2024-10-04T00:03:42.988Z",
                        requested_asin: "B0BH519KQ5",
                        main_image_filename: "81jiA7HfUzL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-04T00:01:01.806Z",
                    },
                    {
                        notification_id: 5351,
                        read_at: null,
                        created_at: "2024-10-08T00:04:48.857Z",
                        requested_asin: "B0BH519KQ5",
                        main_image_filename: "81jiA7HfUzL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T00:01:39.771Z",
                    },
                    {
                        notification_id: 4497,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-30T00:02:39.220Z",
                        requested_asin: "B0BH519KQ5",
                        main_image_filename: "81jiA7HfUzL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:52.028Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5371,
                        read_at: null,
                        created_at: "2024-10-08T00:04:50.369Z",
                        requested_asin: "B08MZPXSK4",
                        main_image_filename: "71U3bUx8mOL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T00:02:17.680Z",
                    },
                    {
                        notification_id: 4199,
                        read_at: "2024-10-01T16:30:17.896Z",
                        created_at: "2024-09-27T00:02:48.177Z",
                        requested_asin: "B08MZPXSK4",
                        main_image_filename: "71U3bUx8mOL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T00:01:04.898Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5538,
                        read_at: null,
                        created_at: "2024-10-08T12:20:17.509Z",
                        requested_asin: "B0C5RZW6ZQ",
                        main_image_filename: "81KzJ+TQRZL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "NEW_TP",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 192,
                user: 93,
                name: "Amaizing Soft Fishing Lures",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-11T08:07:54.900Z",
                last_updated_at: "2024-09-27T15:42:09.618Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5437,
                        read_at: null,
                        created_at: "2024-10-08T12:03:41.686Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T12:01:00.143Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 229,
                user: 93,
                name: "Amaizing Soft Fishing Lures test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-26T10:16:25.709Z",
                last_updated_at: "2024-09-27T15:42:09.786Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5442,
                        read_at: null,
                        created_at: "2024-10-08T12:03:41.694Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T12:01:00.143Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5539,
                        read_at: null,
                        created_at: "2024-10-08T12:49:14.076Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 229,
                user: 93,
                name: "Amaizing Soft Fishing Lures test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-26T10:16:25.709Z",
                last_updated_at: "2024-09-27T15:42:09.786Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 4078,
                        read_at: "2024-10-04T09:52:18.655Z",
                        created_at: "2024-09-26T10:19:08.255Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 193,
                user: 93,
                name: "Amaizing Air Fryers test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Kitchen & Dining",
                created_at: "2024-09-11T08:17:12.574Z",
                last_updated_at: "2024-09-27T15:42:09.629Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4983,
                        read_at: null,
                        created_at: "2024-10-05T00:02:48.645Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [
                            "videos",
                            "title_id",
                            "bullets_id",
                            "main_image_id",
                            "actual_asin_id",
                            "availability_id",
                            "carousel_images_id",
                        ],
                        total_changes: 7,
                        pd_timestamp: "2024-10-05T00:00:46.460Z",
                    },
                    {
                        notification_id: 4819,
                        read_at: "2024-10-04T12:54:44.678Z",
                        created_at: "2024-10-03T00:03:45.719Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [
                            "price",
                            "videos",
                            "title_id",
                            "bullets_id",
                            "main_image_id",
                            "actual_asin_id",
                            "availability_id",
                            "carousel_images_id",
                        ],
                        total_changes: 8,
                        pd_timestamp: "2024-10-03T00:01:19.825Z",
                    },
                    {
                        notification_id: 5283,
                        read_at: null,
                        created_at: "2024-10-07T12:03:38.546Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T12:01:13.400Z",
                    },
                    {
                        notification_id: 4155,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-27T00:02:43.930Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-27T00:00:52.310Z",
                    },
                    {
                        notification_id: 4291,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-28T00:02:48.140Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-28T00:01:02.764Z",
                    },
                    {
                        notification_id: 4340,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-28T12:02:34.272Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [
                            "videos",
                            "title_id",
                            "bullets_id",
                            "main_image_id",
                            "actual_asin_id",
                            "availability_id",
                            "carousel_images_id",
                        ],
                        total_changes: 7,
                        pd_timestamp: "2024-09-28T12:00:45.638Z",
                    },
                    {
                        notification_id: 4063,
                        read_at: "2024-10-01T16:22:15.550Z",
                        created_at: "2024-09-26T00:02:29.972Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T00:00:58.888Z",
                    },
                    {
                        notification_id: 4132,
                        read_at: "2024-10-01T16:22:15.550Z",
                        created_at: "2024-09-26T12:02:39.109Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T12:01:02.922Z",
                    },
                ],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5513,
                        read_at: null,
                        created_at: "2024-10-08T12:03:46.005Z",
                        requested_asin: "B0D3JGCLFR",
                        main_image_filename: "61g41IPyG2L._AC_SL1500_.jpg",
                        type: "DATA_UNAVAILABLE",
                        context: "REGULAR_TRACKING",
                    },
                    {
                        notification_id: 5647,
                        read_at: null,
                        created_at: "2024-10-09T00:03:53.581Z",
                        requested_asin: "B0D3JGCLFR",
                        main_image_filename: "61g41IPyG2L._AC_SL1500_.jpg",
                        type: "DATA_UNAVAILABLE",
                        context: "REGULAR_TRACKING",
                    },
                    {
                        notification_id: 5729,
                        read_at: null,
                        created_at: "2024-10-09T12:04:45.467Z",
                        requested_asin: "B0D3JGCLFR",
                        main_image_filename: "61g41IPyG2L._AC_SL1500_.jpg",
                        type: "DATA_UNAVAILABLE",
                        context: "REGULAR_TRACKING",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 195,
                user: 93,
                name: "Amaizing Dog Bone Broth",
                status: "ACTIVE",
                amazon_tld: "co.uk",
                large_category: "Pet Supplies",
                created_at: "2024-09-11T08:49:35.247Z",
                last_updated_at: "2024-09-27T15:42:09.661Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5154,
                        read_at: null,
                        created_at: "2024-10-06T12:04:25.584Z",
                        requested_asin: "B0CN3PPHGT",
                        main_image_filename: "81YJjU0mdqL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-06T12:01:30.325Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 175,
                user: 93,
                name: "Amaizing Dog Bone Broth",
                status: "ACTIVE",
                amazon_tld: "co.uk",
                large_category: "Pet Supplies",
                created_at: "2024-09-04T07:08:19.407Z",
                last_updated_at: "2024-10-03T14:22:59.343Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5156,
                        read_at: null,
                        created_at: "2024-10-06T12:04:25.588Z",
                        requested_asin: "B0CN3PPHGT",
                        main_image_filename: "81YJjU0mdqL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-06T12:01:30.325Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 229,
                user: 93,
                name: "Amaizing Soft Fishing Lures test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-26T10:16:25.709Z",
                last_updated_at: "2024-09-27T15:42:09.786Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 4079,
                        read_at: null,
                        created_at: "2024-09-26T10:19:08.271Z",
                        requested_asin: "B0CWY6BLFC",
                        main_image_filename: "81gjenh4OIL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5540,
                        read_at: null,
                        created_at: "2024-10-08T14:01:22.442Z",
                        requested_asin: "B0CWY6BLFC",
                        main_image_filename: "81gjenh4OIL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 189,
                user: 93,
                name: "Amaizing Air Rifles",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-11T07:43:18.689Z",
                last_updated_at: "2024-09-27T15:42:09.590Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4537,
                        read_at: "2024-10-02T11:42:01.581Z",
                        created_at: "2024-09-30T12:02:42.790Z",
                        requested_asin: "B00JRHKAO8",
                        main_image_filename: "61DxOzWcK8L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-30T12:00:56.040Z",
                    },
                    {
                        notification_id: 4307,
                        read_at: "2024-10-02T11:42:01.581Z",
                        created_at: "2024-09-28T00:02:49.446Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-28T00:01:05.075Z",
                    },
                    {
                        notification_id: 4785,
                        read_at: "2024-10-03T12:04:17.443Z",
                        created_at: "2024-10-03T00:03:39.117Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-03T00:00:52.919Z",
                    },
                    {
                        notification_id: 5116,
                        read_at: null,
                        created_at: "2024-10-06T00:03:55.963Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id", "availability_id"],
                        total_changes: 3,
                        pd_timestamp: "2024-10-06T00:01:09.238Z",
                    },
                    {
                        notification_id: 5161,
                        read_at: null,
                        created_at: "2024-10-06T12:04:26.672Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id", "availability_id"],
                        total_changes: 3,
                        pd_timestamp: "2024-10-06T12:01:34.658Z",
                    },
                    {
                        notification_id: 5362,
                        read_at: null,
                        created_at: "2024-10-08T00:04:50.183Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T00:02:16.044Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5541,
                        read_at: null,
                        created_at: "2024-10-08T14:01:22.454Z",
                        requested_asin: "B0D2Z1HWT7",
                        main_image_filename: "61ZXAzeMQGL._AC_SL1080_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
    ],
}
