import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { IndicatorIcon } from "../../../../assets/svgs/indicatorIcon.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import StepIndicator from "../../../common/stepIndicator.component"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import TagInputElement from "../../../elements/tagInput.element"

const AddTrackPackStepOneView: React.FC<AddTrackPackProps> = ({
    totalSteps,
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    currentStep,
    isPrevButton,
    handlePrev,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const total_asins = watch("total_asins")
    const data = useSelector(ProductStateSelector)
    const { selectedTrackpacksList } = useSelector(AccountStateSelector)

    const onSubmit = () => {
        const totalAsins = total_asins.split(",").map((asin: string) => asin.trim())
        dispatch(ProductsActionCreator.selectedTrackpackPayload({ total_asins: totalAsins }))
        handleNext && handleNext()
    }
    useEffect(() => {
        if (data?.selectedTrackpackPayload?.total_asins) {
            setValue("total_asins", data?.selectedTrackpackPayload.total_asins?.join(", "))
        }
    }, [data, setValue])

    const trackpackTitle = selectedTrackpacksList?.id
        ? selectedTrackpacksList?.title
        : data.selectedDropdownList?.trackpacks.find((val: any) => val.metadata.id === selectedTrackpacksList)?.metadata
              ?.name

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>
            {asinHeaderDescription ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] ml-[6px]">
                    {asinHeaderDescription}
                </PrimaryText>
            ) : (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] ml-[6px]">
                To add ASINs to your Trackpack called&nbsp;
                <PrimaryText weight="medium" className="text-gray-700 text-[16px] inline">
                    {trackpackTitle},&nbsp;
                </PrimaryText>
                <PrimaryText size="sm-medium" weight="light" className="inline text-gray-700">
                    please give me one ASIN or URL at a time and hit Tab to enter.
                </PrimaryText>
            </PrimaryText>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[40px]">
                <>
                    <TagInputElement
                        name="total_asins"
                        className=" border-[#D0D5DD] rounded-[12px] placeholder:italic tag-input"
                        placeholder="E.g. B08S6XWCN5 or www.amazon.com/DP/B08S6XWCN5"
                        reactHookControl={control}
                        postIcon={<IndicatorIcon />}
                    />
                    <div className="flex justify-center items-center mt-12">
                        <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />
                    </div>

                    {isPrevButton ? (
                        <div>
                            <div className="flex justify-between  items-center mt-[48px]">
                                <div className="flex items-center">
                                    <BackChevronIcon />
                                    <PrimaryText
                                        onClick={handlePrev}
                                        className="text-[18px]  cursor-pointer text-[#667085]"
                                    >
                                        Back
                                    </PrimaryText>
                                </div>

                                <ButtonElement size="large" className={"text-[#0E7090]"}>
                                    {buttonText}
                                </ButtonElement>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center mt-[48px]">
                            <ButtonElement
                                type="submit"
                                size="large"
                                className={"w-[99px] bg-[#A5F0FC] rounded-[8px] h-[44px] text-[#0E7090] text-[14px]"}
                            >
                                {buttonText}
                            </ButtonElement>
                        </div>
                    )}
                </>
            </form>
        </>
    )
}

export default AddTrackPackStepOneView
