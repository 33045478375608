import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import StepIndicator from "../../../common/stepIndicator.component"
import { ButtonElement } from "../../../elements/button.element"
import { NewCheckboxElement } from "../../../elements/newCheckbox.element"
import { PrimaryText } from "../../../elements/primaryText.element"

const AddTrackPackStepTwoView: React.FC<AddTrackPackProps> = ({
    totalSteps,
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handleNext,
    handlePrev,
    buttonText,
    currentStep,
    isAddtionalSteps,
    isError,
}) => {
    const data = useSelector(ProductStateSelector)
    const { selectedTrackpacksList, userActionData } = useSelector(AccountStateSelector)
    const dispatch = useDispatch()
    const trueSelectedCheckbox = data.selectedTrackpackPayload?.selected_Asins || {}
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.addAdditionalAsinLoading(), [loading])

    const defaultValues = {
        asins_list: Object.keys(trueSelectedCheckbox).reduce(
            (acc, key) => {
                acc[key] = trueSelectedCheckbox[key] ?? false
                return acc
            },
            {} as Record<string, boolean>
        ),
    }

    const { control, handleSubmit, watch } = useForm({ defaultValues })
    const asinsSelected = watch("asins_list") || {}

    const allAsinsList =
        data?.selectedTrackpackPayload?.total_asins?.map((val) => {
            return {
                label: val,
                name: val,
                value: trueSelectedCheckbox[val] ?? false,
            }
        }) || []

    const onSubmit = () => {
        if (isAddtionalSteps) {
            const own_asins = Object.keys(asinsSelected).filter((key) => asinsSelected[key])
            let competitor: string[] = []
            if (own_asins.length > 0) {
                competitor =
                    data?.selectedTrackpackPayload?.total_asins?.filter((asin) => !own_asins.includes(asin)) || []
            } else {
                competitor = data?.selectedTrackpackPayload?.total_asins || []
            }
            const payload = {
                own_asins: own_asins?.map((val: string) => val.toUpperCase()) ?? [],
                trackpack_id: selectedTrackpacksList?.id ? selectedTrackpacksList?.id : selectedTrackpacksList,
                competitor_asins: competitor?.map((val: string) => val?.toUpperCase()) ?? [],
            }

            dispatch(
                ProductsActionCreator.addAsinToTrackpack(
                    { ...payload },
                    () => {
                        handleCloseDialog && handleCloseDialog()
                        if (userActionData !== undefined) {
                            dispatch(
                                ProductsActionCreator.getDropdownList(
                                    {},
                                    { as_user: userActionData && userActionData?.id + "" }
                                )
                            )
                        } else dispatch(ProductsActionCreator.getDropdownList())
                    },
                    (res: { message: string }) => {
                        if (isError) {
                            isError()
                        }
                    }
                )
            )
        } else {
            const selectedAsinsObject: AsinsSelection = {}
            Object?.keys(asinsSelected)?.forEach((key) => {
                if (asinsSelected[key]) {
                    selectedAsinsObject[key] = true
                }
            })

            dispatch(ProductsActionCreator.selectedTrackpackPayload({ selected_Asins: selectedAsinsObject }))
            handleNext && handleNext()
        }
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            {/* <TrackPackModalHeader /> */}
            <PrimaryText weight="medium" className=" text-gray-700 text-[24px] ml-[6px] mt-[24px]">
                {asinHeaderText}
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] mt-[16px] ml-[6px] text-[#667085]">
                {asinHeaderDescription}
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                <div className="mt-[40px] flex flex-wrap min-h-[107px] overflow-y-auto">
                    {allAsinsList?.map((el, index) => (
                        <div key={index} className="flex-grow-0 flex-shrink-0 basis-[33.3%]">
                            <NewCheckboxElement
                                defaultValue={el.value}
                                className="pl-0 !mt-[0]"
                                name={`asins_list[${el.label}]`}
                                label={el.label}
                                reactHookControl={control}
                                labelMarginBottom={true}
                                isAsin={true}
                            />
                        </div>
                    ))}
                </div>

                <div className="flex justify-center items-center mt-12">
                    <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />
                </div>
                <div className="flex justify-between items-center mt-[48px]">
                    <div className="flex items-center">
                        <BackChevronIcon />
                        <PrimaryText onClick={handlePrev} className="text-[18px] cursor-pointer text-[#667085]">
                            Back
                        </PrimaryText>
                    </div>

                    <ButtonElement
                        type="submit"
                        size="large"
                        className={"text-[#0E7090]"}
                        loading={isLoading}
                        disabled={isLoading}
                        viewType={isLoading ? "loading-button" : "primary"}
                    >
                        {buttonText}
                    </ButtonElement>
                </div>
            </form>
        </>
    )
}

export default AddTrackPackStepTwoView
